import { Elements } from '@stripe/react-stripe-js';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Col, Nav, NavItem, NavLink, Row } from 'reactstrap';
import { CheckoutService } from '../../../services';
import StripeCard from './StripeCard';

const Checkout = ({ stripePromise }) => {
  const [activePlan, setActivePlan] = useState(null);
  const [planLabel, setPlanLabel] = useState('');
  const [error, setError] = useState(null);

  const { data: planData = {} } = useQuery(
    'getPlans',
    CheckoutService.getPlans,
    {
      retry: false,
    },
  );

  useEffect(() => {
    if (planData?.plans) {
      setActivePlan(planData.plans[0]);
    }
  }, [planData]);

  useEffect(() => {
    if (!activePlan) return;

    let cost, expiresOn;

    if (activePlan.duration === 'monthly') {
      expiresOn = moment().add(1, 'months');
    } else if (activePlan.duration === 'yearly') {
      expiresOn = moment().add(1, 'years');
    }

    cost = activePlan.price === 3 ? 2.99 : activePlan.price;
    setPlanLabel(
      `Total Due: $${cost.toFixed(2)} (Expires on ${expiresOn.format('MMMM D, YYYY')})`,
    );
  }, [activePlan]);

  const plans = planData.plans || [];

  return (
    <Row>
      <Col xs={12}>
        <div className="rounded shadow p-4">
          <div className="d-flex align-items-center justify-content-center">
            <h5 className="mb-0">Discover the Power of Our Premium Upgrade</h5>
          </div>

          <Row style={{ marginTop: 20 }}>
            <Col>
              <div className="text-center">
                <Nav
                  pills
                  justified
                  className="nav-pills rounded-pill justify-content-center d-inline-block border py-1 px-2"
                >
                  {plans.map((p) => (
                    <NavItem className="d-inline-block" key={p.plan_id}>
                      <NavLink
                        className={`px-3 rounded-pill ${
                          activePlan?.id === p.id ? 'active' : ''
                        }`}
                        onClick={() => {
                          setActivePlan(p);
                        }}
                      >
                        {String(p.duration).charAt(0).toUpperCase() +
                          String(p.duration).slice(1)}
                        {p.duration === 'yearly' && (
                          <>
                            &nbsp;
                            <span className="badge rounded-pill bg-success">
                              &nbsp;30% Off&nbsp;
                            </span>
                          </>
                        )}
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              {planLabel && <p className="text-center mt-3">{planLabel}</p>}
              <Elements stripe={stripePromise}>
                <StripeCard plan={activePlan} setError={setError} />
                {error && (
                  <p
                    style={{
                      color: '#ff5043',
                      textAlign: 'center',
                      marginTop: 10,
                    }}
                  >
                    {error}
                  </p>
                )}
              </Elements>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default Checkout;
