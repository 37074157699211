// React Basic and Bootstrap
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Container,
  Row,
} from 'reactstrap';
import ImageAddVocabularyDark from '../../assets/images/extension/add_vocabulary_dark.png';
import ImageDashboard from '../../assets/images/extension/dashboard2.png';
import ImageFlashCard from '../../assets/images/extension/flashcard-cursor-2.png';
import ImageHighlightDark from '../../assets/images/extension/highlight-dark.png';
import ImageRephrase from '../../assets/images/extension/rephrase-cursor-2.png';
import ImageNotification from '../../assets/images/extension/notification.png';
import AddPhrase from '../../assets/images/extension/add_phrase-2.png';
import ImageVocabularyList from '../../assets/images/extension/vocabulary_list-new-2.png';
import ImagePictureFeature from '../../assets/images/extension/picture-2.png';
import BackToTop from '../../components/Layout/backToTop';
import { FREE_VERSION } from '../../config';
import Pricing from './Pricing';
import Testi from './Testi';
import Section from './section';

const Index = () => {
  useEffect(() => {
    document.body.classList = '';
    window.addEventListener('scroll', scrollNavigation, true);
  });

  const scrollNavigation = () => {
    var doc = document.documentElement;
    const navBar = document.getElementById('topnav');
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (navBar != null) {
      if (top > 80) {
        navBar.classList.add('nav-sticky');
      } else {
        navBar.classList.remove('nav-sticky');
      }
    }
  };

  const [col1, setCol1] = useState(false);
  const [col2, setCol2] = useState(false);
  const [col3, setCol3] = useState(false);
  const [col4, setCol4] = useState(false);
  const [col5, setCol5] = useState(false);

  const toggleCol1 = () => {
    setCol1(!col1);
  };

  const toggleCol2 = () => {
    setCol2(!col2);
  };

  const toggleCol3 = () => {
    setCol3(!col3);
  };

  const toggleCol4 = () => {
    setCol4(!col4);
  };

  const toggleCol5 = () => {
    setCol5(!col5);
  };

  return (
    <React.Fragment>
      <Section />

      <div className="position-relative">
        <div className="shape overflow-hidden text-light">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <Container className="mt-100 mt-60 md-padding-20">
        <Row className="align-items-center">
          <Col lg={6} md={6} className="text-center">
            <img
              src={ImageVocabularyList}
              className="img-fluid shadow rounded"
              alt=""
              style={{
                width: 350,
                border: '1px solid #8b5cf6',
                borderRadius: '5px',
              }}
            />
          </Col>

          <Col lg={6} md={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
            <div className="section-title ms-lg-5">
              <h4 className="title mb-4">
                Build Your Vocabulary and Phrase Lists
              </h4>
              <p className="sec-title">
                Create your own vocabulary and phrase lists that match your
                interests while browsing the web.
              </p>
            </div>
          </Col>
        </Row>
      </Container>

      <Container className="mt-100 mt-60 md-padding-20">
        <Row className="align-items-center">
          <Col
            lg={7}
            md={6}
            className="order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0"
          >
            <div className="section-title me-lg-5">
              <h4 className="title mb-4">Real-time Word Highlights</h4>
              <p className="sec-title">
                HighVocab automatically highlights your saved vocabulary as you
                browse the internet. Simply hover over the highlighted words to
                instantly access detailed information and enhance your learning
                experience.
              </p>
            </div>
          </Col>

          <Col lg={5} md={6} className="order-1 order-md-2 text-center">
            <img
              src={ImageHighlightDark}
              className="sm-images"
              alt=""
              style={{ maxWidth: '100%', width: 450, borderRadius: '5px' }}
            />
          </Col>
        </Row>
      </Container>

      <Container className="mt-100 mt-60 md-padding-20">
        <Row className="align-items-center">
          <Col lg={6} md={6} className="text-center">
            <img
              src={ImageAddVocabularyDark}
              className="img-fluid sm-images"
              alt=""
              style={{ maxWidth: '100%', width: 450, borderRadius: '5px' }}
            />
          </Col>

          <Col lg={6} md={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
            <div className="section-title ms-lg-5">
              <h4 className="title mb-4">
                Easily Add Vocabularies with a Simple Click
              </h4>
              <p className="sec-title">
                While browsing the internet, just select any word you want to
                learn, and a pop-up will appear and pick the most suitable
                definition and example, and then add it to your vocabulary.
              </p>
            </div>
          </Col>
        </Row>
      </Container>

      <Container className="mt-100 mt-60 md-padding-20">
        <Row className="align-items-center">
          <Col
            lg={7}
            md={6}
            className="order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0"
          >
            <div className="section-title me-lg-5">
              <h4 className="title mb-4">Image-Enhanced Vocabulary</h4>
              <p className="sec-title">
                View relevant images with your vocabulary words for a more engaging and memorable learning experience!
              </p>
            </div>
          </Col>

          <Col lg={5} md={6} className="order-1 order-md-2">
            <img
              src={ImagePictureFeature}
              alt=""
              className="sm-images"
              style={{
                width: 400,
                border: '1px solid #8b5cf6',
                borderRadius: '5px',
              }}
            />
          </Col>
        </Row>
      </Container>

      <Container className="mt-100 mt-60 md-padding-20">
        <Row className="align-items-center">
          <Col lg={6} md={6} className="text-center">
            <img
              src={ImageFlashCard}
              className="img-fluid sm-images"
              alt=""
              style={{
                width: 380,
                border: '1px solid #8b5cf6',
                borderRadius: '5px',
              }}
            />
          </Col>

          <Col lg={6} md={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
            <div className="section-title ms-lg-5">
              <h4 className="title mb-4">Improve Your Fluency with Expression Flashcards!</h4>
              <p className="sec-title">
                Save any expression, and HighVocab will instantly translate it into your chosen language. Check if you can recall the expression
              </p>
            </div>
          </Col>
        </Row>
      </Container>

      <Container className="mt-100 mt-60 md-padding-20">
        <Row className="align-items-center">
          <Col lg={6} md={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
            <div className="section-title ms-lg-5">
              <h4 className="title mb-4">Style Your Phrases Your Way</h4>
              <p className="sec-title">
                Save and personalize your English phrases in one spot, from bolding to color customization with HighVocab's editor.
              </p>
            </div>
          </Col>
          <Col lg={6} md={6} className="text-center">
            <img
              src={AddPhrase}
              className="img-fluid sm-images"
              alt=""
              style={{
                width: 380,
                border: '1px solid #8b5cf6',
                borderRadius: '5px',
              }}
            />
          </Col>
        </Row>
      </Container>

      <Container className="mt-100 mt-60 md-padding-20">
        <Row className="align-items-center">
          <Col lg={6} md={6} className="text-center">
            <img
              src={ImageRephrase}
              className="img-fluid sm-images"
              alt=""
              style={{
                width: 380,
                border: '1px solid #8b5cf6',
                borderRadius: '5px',
              }}
            />
          </Col>

          <Col lg={6} md={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
            <div className="section-title ms-lg-5">
              <h4 className="title mb-4">Instant Rephrase Feature</h4>
              <p className="sec-title">
                Need help with a tricky sentence or expression? Use HighVocab's
                rephrase feature to find alternative ways to convey your
                thoughts.
              </p>
            </div>
          </Col>
        </Row>
      </Container>

      <Container className="mt-100 mt-60 md-padding-20">
        <Row className="align-items-center">
          <Col lg={6} md={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
            <div className="section-title ms-lg-5">
              <h4 className="title mb-4">Word of the Day Notification</h4>
              <p className="sec-title">
                Get daily reminders to review and memorize words from your list, ensuring steady progress in your language learning journey!
              </p>
            </div>
          </Col>
          <Col lg={6} md={6} className="text-center">
            <img
              src={ImageNotification}
              className="img-fluid sm-images"
              alt=""
              style={{
                width: 380,
                border: '1px solid #8b5cf6',
                borderRadius: '5px',
              }}
            />
          </Col>
        </Row>
      </Container>

      <Container className="mt-100 mt-60 md-padding-20">
        <Row className="align-items-center">
          <Col lg={6} md={6} className="text-center">
            <img
              src={ImageDashboard}
              className="img-fluid sm-images"
              alt=""
              style={{
                width: 380,
                border: '1px solid #8b5cf6',
                borderRadius: '5px',
              }}
            />
          </Col>

          <Col lg={6} md={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
            <div className="section-title ms-lg-5">
              <h4 className="title mb-4">Access Your Vocab and Phrase Lists Anytime, Anywhere</h4>
              <p className="sec-title">
                Access and review your saved vocabulary and phrases using the
                HighVocab dashboard on any device, thanks to our mobile-friendly
                and responsive interface.
              </p>
            </div>
          </Col>
        </Row>
      </Container>

      {/* <Container className="mt-100 mt-60 md-padding-20">
        <Row className="align-items-center">
          <Col
            lg={7}
            md={6}
            className="order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0"
          >
            <div className="section-title me-lg-5">
              <h4 className="title mb-4">Improve Your Language Skills</h4>
              <p className="sec-title">
                Improve your language skills by practicing and memorizing new
                vocabulary and phrases while you browse.
              </p>
            </div>
          </Col>

          <Col lg={5} md={6} className="order-1 order-md-2">
            <img src={appDevelopmentSVG} alt="" />
          </Col>
        </Row>
      </Container> */}

      {!FREE_VERSION && <Pricing />}

      <section className="section pt-0" id="highvocab-faq">
        <Container className="mt-100 mt-60 md-padding-20">
          <Row className="justify-content-center">
            <Col xs={12} className="text-center">
              <div className="section-title">
                <h4 className="title mb-4">Frequently Asked Questions</h4>
              </div>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col lg={9} className="mt-4 pt-2">
              <div className="faq-content">
                <div className="accordion" id="accordionExample">
                  <Card className="border-0 rounded mb-2">
                    <Link
                      to="#"
                      onClick={toggleCol1}
                      className={
                        col1
                          ? 'faq position-relative text-primary'
                          : 'faq position-relative text-dark'
                      }
                    >
                      <CardHeader
                        className="border-0 shadow bg-light p-3"
                        id="headingOne"
                      >
                        <h6 className="title mb-0">
                          {' '}
                          How do I add a new word to my list?
                          <i
                            className={
                              col1
                                ? 'mdi mdi-chevron-up float-end'
                                : 'mdi mdi-chevron-down float-end'
                            }
                          ></i>
                        </h6>
                      </CardHeader>
                    </Link>
                    <Collapse isOpen={col1}>
                      <CardBody>
                        <p className="text-muted mb-0 faq-ans">
                          Simply select the word you want to add while browsing.
                          A pop-up will appear, allowing you to select your
                          desired definition and examples, and once you click
                          'Add Word', it will be saved to your list.
                        </p>
                      </CardBody>
                    </Collapse>
                  </Card>

                  <Card className="border-0 rounded mb-2">
                    <Link
                      to="#"
                      onClick={toggleCol5}
                      className={
                        col5
                          ? 'faq position-relative text-primary'
                          : 'faq position-relative text-dark'
                      }
                    >
                      <CardHeader
                        className="border-0 shadow bg-light p-3"
                        id="headingOne"
                      >
                        <h6 className="title mb-0">
                          {' '}
                          How do I add a phrase to my list?
                          <i
                            className={
                              col5
                                ? 'mdi mdi-chevron-up float-end'
                                : 'mdi mdi-chevron-down float-end'
                            }
                          ></i>
                        </h6>
                      </CardHeader>
                    </Link>
                    <Collapse isOpen={col5}>
                      <CardBody>
                        <p className="text-muted mb-0 faq-ans">
                          To add a phrase, first pin the extension icon
                          in your browser. Then, click the icon and navigate to
                          the 'Phrase' tab. Here, you can easily input and save
                          the phrases you'd like to add to your list.
                        </p>
                      </CardBody>
                    </Collapse>
                  </Card>

                  <Card className="border-0 rounded mb-2">
                    <Link
                      to="#"
                      onClick={toggleCol2}
                      className={
                        col2
                          ? 'faq position-relative text-primary'
                          : 'faq position-relative text-dark'
                      }
                    >
                      <CardHeader
                        className="border-0 shadow bg-light p-3"
                        id="headingTwo"
                      >
                        <h6 className="title mb-0">
                          {' '}
                          Can I view details for the highlighted words?
                          <i
                            className={
                              col2
                                ? 'mdi mdi-chevron-up float-end'
                                : 'mdi mdi-chevron-down float-end'
                            }
                          ></i>
                        </h6>
                      </CardHeader>
                    </Link>
                    <Collapse isOpen={col2}>
                      <CardBody>
                        <p className="text-muted mb-0 faq-ans">
                          Yes, Simply hover over the highlighted words and
                          instantly access detailed information
                        </p>
                      </CardBody>
                    </Collapse>
                  </Card>

                  <Card className="border-0 rounded mb-2">
                    <Link
                      to="#"
                      onClick={toggleCol3}
                      className={
                        col3
                          ? 'faq position-relative text-primary'
                          : 'faq position-relative text-dark'
                      }
                    >
                      <CardHeader
                        className="border-0 shadow bg-light p-3"
                        id="headingTwo"
                      >
                        <h6 className="title mb-0">
                          {' '}
                          What types of languages are available on this
                          extension?
                          <i
                            className={
                              col3
                                ? 'mdi mdi-chevron-up float-end'
                                : 'mdi mdi-chevron-down float-end'
                            }
                          ></i>
                        </h6>
                      </CardHeader>
                    </Link>
                    <Collapse isOpen={col3}>
                      <CardBody>
                        <p className="text-muted mb-0 faq-ans">
                          Currently, the extension is only for English learners
                          and can be translated into over 60 languages.
                        </p>
                      </CardBody>
                    </Collapse>
                  </Card>

                  <Card className="border-0 rounded mb-2">
                    <Link
                      to="#"
                      onClick={toggleCol4}
                      className={
                        col4
                          ? 'faq position-relative text-primary'
                          : 'faq position-relative text-dark'
                      }
                    >
                      <CardHeader
                        className="border-0 shadow bg-light p-3"
                        id="headingTwo"
                      >
                        <h6 className="title mb-0">
                          {' '}
                          Can I export my vocabulary list to a separate file?
                          <i
                            className={
                              col4
                                ? 'mdi mdi-chevron-up float-end'
                                : 'mdi mdi-chevron-down float-end'
                            }
                          ></i>
                        </h6>
                      </CardHeader>
                    </Link>
                    <Collapse isOpen={col4}>
                      <CardBody>
                        <p className="text-muted mb-0 faq-ans">
                          Yes, HighVocab supports exporting your vocab and
                          phrase list as a CSV file which can be opened with
                          Microsoft Excel or similar programs.
                        </p>
                      </CardBody>
                    </Collapse>
                  </Card>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Testi />

      <div className="position-relative">
        <div className="shape overflow-hidden text-footer">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <BackToTop />
    </React.Fragment>
  );
};

export default Index;
