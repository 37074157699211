import PagePayments from '../pages/Pages/Account/PagePayments';
import PagePhrases from '../pages/Pages/Account/PagePhrases';
import PageSettings from '../pages/Pages/Account/PageSettings';
import PageVocabularies from '../pages/Pages/Account/PageVocabularies';
import PageRecoveryPassword from '../pages/Pages/AuthPages/PageRecoveryPassword';
import ResetPassword from '../pages/Pages/AuthPages/ResetPassword';
import Blog from '../pages/Pages/Blog/index';
import AuthLogin from '../pages/Pages/DemoJob/AuthLogin';
import AuthSignup from '../pages/Pages/DemoJob/AuthSignup';
import EmailAlert from '../pages/Pages/EmailTemplate/EmailAlert';
import EmailConfirmation from '../pages/Pages/EmailTemplate/EmailConfirmation';
import EmailInvoice from '../pages/Pages/EmailTemplate/EmailInvoice';
import EmailPasswordReset from '../pages/Pages/EmailTemplate/EmailPasswordReset';
import PageMaintenance from '../pages/Pages/Special/PageMaintenance';
import PageThankYou from '../pages/Pages/Special/PageThankYou';
import SuccessPasswordReset from '../pages/Pages/Special/SuccessPasswordReset';
import PagePrivacy from '../pages/Pages/Utility/PagePrivacy';
import PageTerms from '../pages/Pages/Utility/PageTerms';

export const commonRoutes = [
  // Email
  { path: '/email-alert', component: EmailAlert, isWithoutLayout: true },
  {
    path: '/email-password-reset',
    component: EmailPasswordReset,
    isWithoutLayout: true,
  },
  {
    path: '/email-confirmation',
    component: EmailConfirmation,
    isWithoutLayout: true,
  },
  { path: '/email-invoice', component: EmailInvoice, isWithoutLayout: true },

  // Special Pages
  { path: '/thankyou', component: PageThankYou, isWithoutLayout: true },
  {
    path: '/success/password_reset',
    component: SuccessPasswordReset,
    isWithoutLayout: true,
  },
  {
    path: '/maintenance',
    component: PageMaintenance,
    isWithoutLayout: true,
  },

  {
    path: '/blog',
    component: Blog,
    isWithoutLayout: false,
  },

  //Utility
  { path: '/terms', component: PageTerms, isTopbarDark: true },
  { path: '/privacy', component: PagePrivacy, isTopbarDark: true },
];

export const unAuthRoutes = [
  // User Pages
  {
    path: '/forgot_password',
    component: PageRecoveryPassword,
    isWithoutLayout: true,
  },
  {
    path: '/reset_password/:token',
    component: ResetPassword,
    isWithoutLayout: true,
  },
  { path: '/login', component: AuthLogin, isWithoutLayout: true },
  { path: '/signup', component: AuthSignup, isWithoutLayout: true },

  //Index Main
  // { path: '/', component: Apps },
  // { path: '/index', component: Apps },
];

export const authRoutes = [
  { path: '/payment', component: PagePayments },
  { path: '/vocabularies', component: PageVocabularies },
  { path: '/phrases', component: PagePhrases },
  { path: '/settings', component: PageSettings },
];
