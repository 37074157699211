import React, { useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import blog14 from "../../../assets/images/pronunciation.jpg";

const PageBlogList = () => {
  const [blogs] = useState([
    { id: 1, image: blog14, title: "How to Improve Pronunciation Effectively", desc: "The International Phonetic Alphabet (IPA) helps with pronunciation by matching each sound to a specific symbol.", like: 1000, comment: 0, autor: "HighVocab", date: "8th March, 2025" },
  ]);

  return (
    <>
      <section className="section">
        <Container>
          <Row>
            {blogs.map((blog, key) => (
              <Col lg={6} xs={12} key={key} className="mb-4 pb-2">
                <Card className="blog rounded border-0 shadow overflow-hidden">
                  <Row className="align-items-center g-0">
                    <Col md={6}>
                      <img src={blog.image} className="img-fluid" alt="Landrick" />
                      <div className="overlay bg-dark"></div>
                      <div className="author">
                        <small className="text-light user d-block">
                          <i className="uil uil-user"></i> {blog.autor}
                        </small>
                        <small className="text-light date">
                          <i className="uil uil-calendar-alt"></i> {blog.date}
                        </small>
                      </div>
                    </Col>
                    <Col md={6}>
                      <CardBody className="content">
                        <h5>
                          <a href="/ipa.html" className="card-title title text-dark">
                            {blog.title}
                          </a>
                        </h5>
                        <p className="text-muted mb-0">{blog.desc}</p>
                        <div className="post-meta d-flex justify-content-between mt-3">
                          <ul className="list-unstyled mb-0">
                            <li className="list-inline-item me-2 mb-0">
                              <a href="/ipa.html" className="text-muted like">
                                <i className="uil uil-heart me-1"></i> {blog.like}
                              </a>
                            </li>
                            <li className="list-inline-item">
                              <a href="/ipa.html" className="text-muted comments">
                                <i className="uil uil-comment me-1"></i> {blog.comment}
                              </a>
                            </li>
                          </ul>
                          <a href="/ipa.html" className="text-muted readmore">
                            Read More <i className="uil uil-angle-right-b align-middle"></i>
                          </a>
                        </div>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </>
  );
};

export default PageBlogList;