import React from 'react';
import { Link } from 'react-router-dom';

const NavbarButtons = ({ currentUser }) => {

  return (
    <React.Fragment>
      <ul className="buy-button list-inline mb-0">
        {
          currentUser ?
            <>
              <li className="list-inline-item btn-icon button mb-0 ps-1">
                <Link
                  to="/blog"
                  className="btn btn-md btn-primary btn-primary"
                >
                  Blogs
                </Link>
              </li>
              <li className="list-inline-item btn-icon button mb-0 ps-1">
                <Link
                  to="/vocabularies"
                  className="btn btn-md btn-primary btn-primary"
                >
                  Dashboard
                </Link>
              </li>
            </>
            :
            <>
              <li className="list-inline-item btn-icon button mb-0 ps-1">
                <Link
                  to="/login"
                  className="btn btn-md btn-primary btn-light"
                >
                  Log In
                </Link>
              </li>
              <li className="list-inline-item button mb-0 ps-2">
                <Link to="/signup" className="btn btn-md btn-primary">
                  Sign Up
                </Link>
              </li>
            </>
        }

      </ul>
    </React.Fragment>
  );
};

export default NavbarButtons;
